import {StarRating} from '../vendor/star-rating.js';
function setup() {
    document.querySelectorAll('.btn-outline').forEach(button => {
        button.addEventListener('touchstart', function (event) {
            event.preventDefault();
            this.classList.add('btn-outline-focus');
        });
        button.addEventListener('touchend', function (event) {
            this.classList.remove('btn-outline-focus');
            addHiddenFieldAndSubmit(event, button);
        });

        button.addEventListener('touchcancel', function () {
            this.classList.remove('btn-outline-focus');
        });

        if (button.dataset.submitOnChange) {
            button.addEventListener('change', function (event) {
                addHiddenFieldAndSubmit(event, this);
            });
        }
    });


    const star_rating = new StarRating('.star-rating');

}

function addHiddenFieldAndSubmit(event, element) {
    event.preventDefault();

    const form = element.closest('form');
    const value = element.value || element.dataset.value;
    if (!form || !value || form.dataset.submitting === 'true') {
        return;
    }

    form.dataset.submitting = 'true';

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'commit';
    hiddenField.value = value;

    form.appendChild(hiddenField);

    if (form.querySelector('input[name="commit"]')) {
        form.querySelectorAll('button, input[type="submit"]').forEach(button => {
            button.disabled = true;
        });

        form.submit();
    }
    else {
        form.dataset.submitting = 'false';
    }
}

document.addEventListener("modal.load", setup);